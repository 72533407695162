<template>
    <NuxtLink no-prefetch class="block" :to="`/${$lang.routes.events}/${event.slug}`" rel="follow">
        <article class="card-event">
            <picture class="card-event__link">
                <img
                    class="card-event__image"
                    :alt="event.title"
                    :title="event.title"
                    :src="event.image.url"
                    loading="lazy"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                />
            </picture>
            <div class="card-event-content">
                <div class="card-event-content__resumen">
                    <h3 class="card-event-content__title">
                        {{ replacePlaceholders([[':year', String(dates.year || '')]], event.title) }}
                    </h3>
                    <p class="card-event-content__description">
                        {{ replacePlaceholders([[':year', String(dates.year || '')]], truncatedContent) }}
                    </p>
                </div>
                <div class="card-event-content__date">
                    <img :src="$assets.primary.calendar" alt="icon" />
                    {{ formatDate(event.start_at) }}
                </div>
            </div>
        </article>
    </NuxtLink>
</template>

<script lang="ts" setup>
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

import { useDateFunctions } from '~/composables/DateFunctions'
import { replacePlaceholders } from '~/util/textFunction'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'

const { defaultImages } = useVariantsDictionary()

const defaultImage = defaultImages['event']

const props = defineProps({
    event: { type: Object, required: true },
    expire: { type: Boolean, default: false },
})

const { currentDate } = useDateFunctions()

const dates = currentDate()

const { $lang } = useNuxtApp()

const formatDate = (date: any) => {
    const newDate = date && parseISO(date)
    return date ? format(newDate, 'MMMM', { locale: es }) : ''
}

const content = computed(() => {
    return props.event.description?.replace(/<[^>]+>/g, '')
})

const truncatedContent = computed(() => {
    if (content.value) {
        const truncated = content.value.slice(0, 180) || ''
        return truncated.length < content.value.length ? truncated + '...' : truncated
    }
    return ''
})
</script>

<style lang="postcss" scoped>
.card-event {
    @apply inline-block w-full rounded-xl bg-white duration-100 ease-in;

    &:hover {
        box-shadow: 0px 4px 50px rgba(54, 18, 73, 0.21);
    }
    &__image {
        @apply h-[229px] w-full rounded-t-xl object-cover;
    }
    &-content {
        @apply flex h-[173px] flex-col justify-between gap-y-2 p-3;
        &__title {
            @apply mb-1 line-clamp-2 cursor-pointer text-lg font-medium;
        }
        &__description {
            @apply line-clamp-4 text-sm text-site-gray;
        }
        &__date {
            @apply flex items-center gap-x-2 capitalize text-site-primary;
        }
    }
}
</style>
