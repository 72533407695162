<template>
    <main class="events-page">
        <section>
            <h1 class="events-page__title">{{ titleH1 }}</h1>
            <h2 class="events-page__subtitle">{{ titleH2 }}</h2>
        </section>

        <section class="events-page-content">
            <nav v-if="events.page.data.next.data && events.page.data.next.data.length">
                <ul class="event-list">
                    <li v-for="event of events.page.data.next.data" :key="event.id">
                        <CardEventNew :event="event" />
                    </li>
                </ul>
            </nav>

            <div v-else class="no-content">
                <h2 class="no-content__text">Sin eventos disponibles en este momento</h2>
            </div>
        </section>

        <template v-if="events.page.content && events.page.content.length">
            <div class="dynamic-content" v-html="events.page.content"></div>
        </template>
    </main>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('forum-response-data', () =>
    $fetch<Api.Responses.Pages.Events>(endpoints.pages.events.path, {
        headers: buildHeaders(),
        baseURL,
        method: 'GET',
    }).catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let events = responseData.value

const currentDates = currentDate()
const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description, titleH1, titleH2] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [events.page.title || '', events.page.description || '', events.page.h1 || '', events.page.h2 || ''],
)
const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const existsAnyEvent = computed(() => {
    return (
        events.page.data.active.data.length > 0 ||
        events.page.data.expired.data.length > 0 ||
        events.page.data.next.data.length > 0
    )
})
</script>

<style lang="postcss" scoped>
.events-page {
    @apply container mt-8 pb-8 lg:max-w-5xl;

    &__title {
        @apply mb-10 text-[28px] font-medium lg:text-[32px];
    }

    &__subtitle {
        @apply text-lg lg:text-[28px];
    }

    & .no-content {
        @apply flex items-center justify-center py-16;

        &__text {
            @apply text-lg lg:text-[28px];
        }
    }

    .event-list {
        @apply mb-6 mt-6 grid grid-cols-1 gap-6 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
    }

    .dynamic-content {
        @apply prose max-w-none rounded-lg bg-white px-2 py-3 prose-headings:font-normal prose-h2:text-lg md:px-4 md:py-6 lg:prose-h2:text-[28px] 2xl:px-8;
    }
}
</style>
